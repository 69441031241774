import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import SbEditable from "storyblok-react"
import useStoryblok from "../utils/storyblok"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import DynamicComponent from "../components/DynamicComponent"
import Android from "../components/android/Android"
import Wallet from "../components/wallet/Wallet"
import Footer from "../components/Footer"

const IndexPage = ({ data, location }) => {
  let story = data.storyblokEntry
  story = useStoryblok(story, location)

  const components = story.content.body.map(blok => {
    return <DynamicComponent blok={blok} key={blok._uid} />
  })

  return (
    <Layout>
      <Seo title="Home" pageName="Home" />
      {components}
      <Wallet />
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
    }
  }
`
