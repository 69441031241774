import * as React from "react"
import PropTypes from "prop-types"
import DynamicComponent from "./DynamicComponent"
import SbEditable from "storyblok-react"

const Grid = ({ blok, len }) => (
  <SbEditable content={blok}>
    <div className={`grid grid-flow-col grid-cols-${len} grid-rows-1`}>
      {blok.columns.map(nestedBlok => (
        <div key={nestedBlok._uid} className="break-words">
          <DynamicComponent blok={nestedBlok} />
        </div>
      ))}
    </div>
  </SbEditable>
)

export default Grid
