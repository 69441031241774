import React from "react"
import SbEditable from "storyblok-react"
import Teaser from "./Teaser"
import Grid from "./Grid"
import Feature from "./Feature"
import Footer from "./Footer"
import PlainH1 from "./headings/PlainH1"
import PlainH2 from "./headings/PlainH2"
import PlainH3 from "./headings/PlainH3"
import PlainP from "./paragraphs/PlainP"
import Small from "./paragraphs/Small"
import ContentPara from "./paragraphs/ContentPara"
import NormalContentPara from "./paragraphs/NormalContentPara"
import H1 from "./headings/H1"
import H2 from "./headings/H2"

const Components = {
  teaser: Teaser,
  grid: Grid,
  feature: Feature,
  footer: Footer,
  PlainH1: PlainH1,
  PlainH2: PlainH2,
  PlainH3: PlainH3,
  PlainP: PlainP,
  Small: Small,
  H1: H1,
  H2: H2,
  ContentPara: ContentPara,
  NormalContentPara: NormalContentPara,
}

const DynamicComponent = ({ blok }) => {
  if (
    typeof Components[blok.component] !== "undefined" &&
    Components[blok.component] !== "Footer"
  ) {
    const Component = Components[blok.component]
    const cols = blok.columns !== undefined ? blok.columns.length : 0

    return (
      <SbEditable content={blok}>
        <Component blok={blok} len={cols} />
      </SbEditable>
    )
  }
  return (
    <p>
      {(() => {
        if (blok.component !== "Footer")
          return (
            <span>
              The component <strong>{blok.component}</strong> has not been
              created yet.
            </span>
          )
      })()}
    </p>
  )
}

export default DynamicComponent
