import * as React from "react"
import { InformationCircleIcon } from "@heroicons/react/solid"

const Teaser = ({ blok, len }) => (
  <div className="rounded-md bg-blue-50 p-4 mt-6 mb-6">
    <div className="flex">
      <div className="flex-shrink-0">
        <InformationCircleIcon
          className="h-5 w-5 text-blue-400"
          aria-hidden="true"
        />
      </div>
      <div className="ml-3 flex-1 md:flex md:justify-between">
        <p className="text-sm text-blue-700">{blok.headline}</p>
        <p className="mt-3 text-sm md:mt-0 md:ml-6"></p>
      </div>
    </div>
  </div>
)

export default Teaser
