import React from "react"
import {
  InboxIcon,
  SparklesIcon,
  ShoppingCartIcon,
} from "@heroicons/react/outline"
import CardList from "../../images/shopsmart_list.png"
import ChangeCountry from "../../images/change_country.png"
import appStore from "../../images/app-store-badge.svg"
import AU from "../../images/australian-flag-small.png"
import US from "../../images/american-flag-small.png"
import playStore from "../../images/google_play.png"
/* eslint-disable */
const wallet = () => (
  <div className="relative bg-white pt-16 pb-6 overflow-hidden">
    <div className="relative">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div>
              <span className="h-12 w-12 rounded-md flex items-center justify-center bg-red-default">
                <ShoppingCartIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div className="mt-6">
              <h2>Got a phone? Download our app.</h2>
              <p className="mt-4 text-lg text-gray-500">
                Forget about carrying cards in your wallet. This is the only
                wallet you will ever need.
              </p>
              <p className="mt-4 text-lg text-gray-500">
                Turn your phone into a mobile wallet and redefine your everyday
                shopping experience!
              </p>
              <p className="mt-4 text-lg text-gray-500">
                We are just making some changes to our app. Please check back
                soon!
              </p>
              <div className="mt-4">
                <h3 className="flex flex-row">
                  <a
                    href="https://itunes.apple.com/au/app/shopsmart-loyalty-card-ewallet/id1360421095?mt=8&uo=4"
                    target="_new"
                  >
                    <img
                      className="app-store-image pt-1 pr-8"
                      src={appStore}
                      alt="Shopsmart in the app store"
                      width="150"
                      height="100"
                    />
                  </a>
                  <a
                    className="col"
                    href="https://play.google.com/store/apps/details?id=com.current.shopsmart"
                    target="_new"
                  >
                    <img
                      className="app-store-image"
                      src={playStore}
                      alt="Shopsmart in the Google play store"
                    />
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 sm:mt-16 lg:mt-0">
          <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img
              className="rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
              src={CardList}
              alt="Shopsmart in the app store"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default wallet
/* eslint-enable */
